export const posts = [
  {
    category: "Productivity",
    title: "CornerStone",
    description: `A Chrome Extension: Focus with Your Tasks, Stay Weather-informed, and Get Inspired with Quotes `,
     link:"https://chromewebstore.google.com/detail/cornerstone/eiblcdbfflafafgokjoeighgdpclhepd"




  },
  {
    category: "Personal Organization and Productivity",
    title: " Super Journal",
    description:
      "Amidst the digital noise, find solace in your digital diary. Super Journal - where keystrokes turn into self-discovery",
      link:"https://superjournal.netlify.app/"


  },
];
